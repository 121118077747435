<template>
  <div class="form-row align-items-center">
    <!-- Label -->
    <div v-bind:class="labelClass">
      <label
        v-bind:for="componentId"
        v-bind:class="['custom-label', disabled ? 'text-muted' : '']"
        >{{ label }}
        <span v-if="showAsterix" class="text-danger">*</span></label
      >
      <template v-if="miniDescription">
        <div class="clearfix"></div>
        <small class="text-muted"><i v-html="miniDescription"></i></small>
      </template>
      <template v-if="hint && hint.length">
        <i class="fas fa-question-circle ml-2" v-tooltip.bottom="hint"></i>
      </template>
    </div>
    <!-- ./Label -->

    <!-- Content -->
    <div v-bind:class="inputClass">
      <!-- Input -->
      <WeInput
        v-if="form == 'input'"
        class="mb-0"
        v-bind:small-size="size == 'sm'"
        v-bind:id="componentId"
        v-bind:placeholder="placeholder"
        v-bind:input="value"
        v-on:input="onInput"
        v-bind:filter-number="filterNumber"
        v-bind:error="error"
        v-bind:disabled="disabled"
      />
      <!-- ./Input -->

      <!-- Date -->
      <WeDatePicker
        v-if="form == 'date'"
        v-bind:id="componentId"
        v-bind:label="$t('date')"
        v-bind:format="'DD.MM.YYYY'"
        v-bind:formatted="'ll'"
        v-bind:output-format="'YYYY-MM-DD'"
        v-bind:locale="'tr'"
        v-bind:no-label="true"
        v-bind:no-button-now="true"
        v-bind:only-date="true"
        v-bind:range="dateRange"
        v-bind:value="value"
        v-bind:custom-shortcuts="dateShortcuts"
        v-bind:shortcut="selectedShortcut"
        v-on:input="onInput"
        input-size="xs"
        v-bind:disabled="disabled"
      />
      <!-- ./Date -->

      <!-- Search -->
      <WeSearchInput
        class="mb-0"
        v-if="form == 'search'"
        result-prop="name"
        v-bind:name="name"
        v-on:on-select="onSelect"
        v-bind:size="size"
        v-bind:disabled="disabled"
        v-bind:placeholder="$t('makeSearch')"
      />
      <!-- ./Search -->

      <!-- V-Select -->
      <v-select
        v-if="form == 'searchSelect'"
        v-bind:class="{
          'custom-v-select-sm': size == 'sm',
          'v-select--error': error,
        }"
        v-bind:options="options"
        v-bind:placeholder="label"
        v-bind:label="selectLabel"
        v-bind:reduce="(r) => r[selectProp]"
        v-bind:clearable="clearable"
        v-bind:multiple="multiple"
        v-bind:close-on-select="closeOnSelect"
        v-on:search="doSearch"
        v-bind:disabled="disabled"
        v-bind:value="value"
        v-on:input="onInput"
      >
        <span slot="no-options">{{ $t("noResultsToShow") }}</span>
        <template #selected-option="value">
          <div
            class="text-truncate v-select__selected"
            v-tooltip.bottom="value[selectLabel]"
          >
            <strong>{{ value[selectLabel] }}</strong>
          </div>
        </template>
      </v-select>

      <v-select
        v-if="form == 'vSelect'"
        v-bind:class="{
          'custom-v-select-sm': size == 'sm',
          'v-select--error': error,
        }"
        v-bind:options="optionProp"
        v-bind:placeholder="label"
        v-bind:label="selectLabel"
        v-bind:clearable="clearable"
        v-bind:reduce="(r) => r[selectProp]"
        v-bind:multiple="multiple"
        v-bind:value="value"
        v-bind:close-on-select="closeOnSelect"
        v-on:input="onInput"
        v-bind:disabled="disabled"
      >
        <span slot="no-options">{{ $t("noResultsToShow") }}</span>
        <template #selected-option="value">
          <div
            class="text-truncate v-select__selected"
            v-tooltip.bottom="value[selectLabel]"
          >
            <strong>{{ value[selectLabel] }}</strong>
          </div>
        </template>
      </v-select>

      <v-select
        v-if="form == 'vPaginatedSelect'"
        v-bind:class="{
          'custom-v-select-sm': size == 'sm',
          'v-select--error': error,
        }"
        v-bind:options="optionProp"
        v-bind:placeholder="label"
        v-bind:label="selectLabel"
        v-bind:clearable="clearable"
        v-bind:reduce="(r) => r[selectProp]"
        v-bind:multiple="multiple"
        v-bind:value="value"
        v-on:search="doSearch"
        v-on:input="onInput"
        v-bind:close-on-select="closeOnSelect"
        v-bind:disabled="disabled"
      >
        <span slot="no-options">{{ $t("noResultsToShow") }}</span>
        <template #selected-option="value">
          <div
            class="text-truncate v-select__selected"
            v-tooltip.bottom="value[selectLabel]"
          >
            <strong>{{ value[selectLabel] }}</strong>
          </div>
        </template>
        <template v-if="optionProp && optionProp.length >= 25" #list-footer>
          <div class="row justify-content-between">
            <div class="col">
              <button
                class="w-100 btn btn-light"
                :disabled="!hasPrevPage"
                @click="$emit('goPrevPage')"
              >
                Önceki
              </button>
            </div>
            <div class="col">
              <button
                class="w-100 btn btn-light"
                :disabled="!hasNextPage"
                @click="$emit('goNextPage')"
              >
                Sonraki
              </button>
            </div>
          </div>
        </template>
      </v-select>

      <v-select
        v-if="form == 'vPaginatedSelectReduce'"
        v-bind:class="{
          'custom-v-select-sm': size == 'sm',
          'v-select--error': error,
        }"
        v-bind:options="optionProp"
        v-bind:placeholder="label"
        v-bind:label="selectLabel"
        v-bind:clearable="clearable"
        v-bind:reduce="(r) => r"
        v-bind:multiple="multiple"
        v-bind:value="value"
        v-on:input="onInput"
        v-bind:close-on-select="closeOnSelect"
        v-bind:disabled="disabled"
      >
        <span slot="no-options">{{ $t("noResultsToShow") }}</span>
        <template v-if="optionProp && optionProp.length >= 10" #list-header>
          <div class="row justify-content-between">
            <div class="col">
              <button class="w-100 btn btn-light" @click="$emit('goAllSelect')">
                Tümünü Seç
              </button>
            </div>
          </div>
        </template>
        <template #selected-option="value">
          <div
            class="text-truncate v-select__selected"
            v-tooltip.bottom="value[selectLabel]"
          >
            <strong>{{ value[selectLabel] }}</strong>
          </div>
        </template>
        <template #list-footer>
          <div class="row justify-content-between">
            <div class="col">
              <button
                class="w-100 btn btn-light"
                :disabled="!hasPrevPage"
                @click="$emit('goPrevPage')"
              >
                Geri
              </button>
            </div>
            <div class="col">
              <button
                class="w-100 btn btn-light"
                :disabled="!hasNextPage"
                @click="$emit('goNextPage')"
              >
                İleri
              </button>
            </div>
          </div>
        </template>
      </v-select>

      <v-select
        v-if="form == 'unreducedSelect'"
        v-bind:class="{
          'custom-v-select-sm': size == 'sm',
          'v-select--error': error,
        }"
        v-bind:options="options"
        v-bind:placeholder="label"
        v-bind:label="selectLabel"
        v-bind:clearable="clearable"
        v-bind:multiple="multiple"
        v-bind:value="value"
        v-bind:close-on-select="closeOnSelect"
        v-on:input="onInput"
        v-bind:disabled="disabled"
      >
        <span slot="no-options">{{ $t("noResultsToShow") }}</span>
        <template #selected-option="value">
          <div
            class="text-truncate v-select__selected"
            v-tooltip.bottom="value[selectLabel]"
          >
            <strong>{{ value[selectLabel] }}</strong>
          </div>
        </template>
      </v-select>

      <v-select
        v-if="form == 'unreducedSearchSelect'"
        v-bind:class="{
          'custom-v-select-sm': size == 'sm',
          'v-select--error': error,
        }"
        v-bind:options="options"
        v-bind:placeholder="label"
        v-bind:label="selectLabel"
        v-bind:clearable="clearable"
        v-bind:multiple="multiple"
        v-bind:close-on-select="closeOnSelect"
        v-on:search="doSearch"
        v-on:input="onInput"
        v-bind:value="value"
        v-bind:disabled="disabled"
      >
        <template slot="no-options">{{ $t("makeSearch") }}</template>
        <template #selected-option="value">
          <div
            class="text-truncate v-select__selected"
            v-tooltip.bottom="value[selectLabel]"
          >
            <strong>{{ value[selectLabel] }}</strong>
          </div>
        </template>
      </v-select>
      <!-- ./V-Select -->

      <!-- Phone -->
      <WePhone
        v-if="form == 'phone'"
        v-on:input="onInput"
        v-bind:show-label="false"
        v-bind:size="size"
        v-bind:disabled="disabled"
        v-bind:label="$t('phoneNumber')"
      />
      <!-- ./Phone -->

      <!-- Switch -->
      <WeSwitch
        v-if="form == 'boolean'"
        v-on:input="onInput"
        v-bind:value="value"
        v-bind:show-status="showStatus"
        v-bind:disabled="disabled"
      />
      <!-- ./Switch -->

      <treeselect
        v-if="form == 'vMultipleSelect'"
        v-bind:value="value"
        :options="optionProp"
        :normalizer="normalizer"
        :multiple="multiple"
        :flat="flat"
        :placeholder="placeholder"
        v-bind:class="{
          'custom-v-select-sm': size == 'sm',
          'v-select--error': error,
        }"
        v-on:input="onInput"
      />
    </div>
    <!-- ./Content -->
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapState } from "vuex";
export default {
  name: "WeRowInput",
  components: { Treeselect },
  data() {
    return {
      dateShortcuts: [
        { key: "thisWeek", label: this.$t("thisWeek"), value: "isoWeek" },
        { key: "lastWeek", label: this.$t("lastWeek"), value: "-isoWeek" },
        { key: "last7Days", label: this.$t("last7Days"), value: 7 },
        { key: "last30Days", label: this.$t("last30Days"), value: 30 },
        { key: "thisMonth", label: this.$t("thisMonth"), value: "month" },
        { key: "lastMonth", label: this.$t("lastMonth"), value: "-month" },
        { key: "thisYear", label: this.$t("thisYear"), value: "year" },
        { key: "lastYear", label: this.$t("lastYear"), value: "-year" },
      ],
      options: [],
      normalizer(node) {
        return {
          children: node.children && node.children.length ? node.children : 0,
          label: node.name,
        };
      },
    };
  },
  props: {
    labelClass: {
      default: "col-12 col-lg-3",
    },
    inputClass: {
      default: "col-12 col-lg-6",
    },
    label: {
      default: "",
    },
    name: {
      default: "",
    },
    expandLevel: {
      default: "1",
    },
    flat: {
      default: false,
    },
    placeholder: {
      default: "",
    },
    value: {
      default: null,
    },
    size: {
      default: "",
    },
    filterNumber: {
      default: false,
    },
    form: {
      default: "input",
    },
    dateRange: {
      default: false,
    },
    selectLabel: {
      default: "name",
    },
    selectProp: {
      default: "id",
    },
    optionProp: {
      default: () => [],
    },
    clearable: {
      default: false,
    },
    multiple: {
      default: false,
    },
    closeOnSelect: {
      default: true,
    },
    error: {
      default: null,
    },
    miniDescription: {
      default: null,
    },
    hint: {
      default: null,
    },
    selectedShortcut: {
      default: null,
    },
    searchRoute: {
      default: null,
    },
    searchForm: {
      default: null,
    },
    searchKey: {
      default: null,
    },
    showStatus: {
      default: false,
    },
    showAsterix: {
      default: false,
    },
    disabled: {
      default: false,
    },
    hasPrevPage: {
      default: false,
    },
    hasNextPage: {
      default: false,
    },
  },
  methods: {
    ...mapActions("shared", ["search"]),
    onInput(e) {
      this.$emit("input", e);
    },
    onSelect(result) {
      let value = null;
      const propIndex = this.selectProp;

      if (propIndex) {
        value = result[propIndex];
      } else {
        value = result;
      }

      this.$emit("input", value);
    },
    doSearch(text, loading) {
      this.searching = false;
      let minLength = 2;
      let timeout = 1000;
      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= minLength) {
          this.emitSearch(text, localTimeout, loading);
        }
      }
    },
    emitSearch(text, timeout, loading) {
      clearTimeout(this.timer);
      loading(true);
      this.searching = true;
      this.options = [];
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);

        // Defined route or Null
        let route = this.searchRoute;

        // Defined (object) searchForm or clean object
        let data = this.searchForm || {};

        // Defined searchKey {query} or Null
        let searchKey = this.searchKey;

        if (!route && !searchKey) {
          route = this.searchState.routes[this.name].route;
          searchKey = this.searchState.routes[this.name].key;
        }

        data[searchKey] = text;

        this.search({
          route: route,
          form: data,
          onSuccess: (result) => {
            if (result && result.data) {
              if (result.data.hasOwnProperty("items")) {
                if (this.form == "vPaginatedSelect") {
                  this.$emit("search-result", result.data.items);
                } else {
                  this.options = result.data.items;
                }
              } else {
                this.options = result.data;
              }
            }
          },
          onFinish: () => {
            this.searching = false;
            loading(false);
          },
        });
      }, timeout);
    },
  },
  computed: {
    ...mapState({
      searchState: (state) => state.shared.search,
    }),
    componentId() {
      return (
        this.name ||
        "row-" + this.form + "-" + helper.getRandomInteger(1, 999999)
      );
    },
  },
  mounted() {
    this.options = this.optionProp;
  },
};
</script>
 
<style>
.vue-treeselect__control {
  border: 1px solid #ced4da !important;
}
.vue-treeselect__placeholder {
  color: #64686c !important;
}
.vue-treeselect__control-arrow {
  color: #3c3c3c80 !important;
}
.vue-treeselect__x {
  width: 9.5px;
  height: 9.5px;
  font-weight: bold;
  color: rgba(60, 60, 60, 0.5) !important;
  margin-left: 4px;
}
.vue-treeselect__control-arrow-container svg {
  display: none !important;
}
.vue-treeselect__control-arrow-container::before {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  content: "\f078" !important ;
  margin-left: 3px;
  color: rgba(60, 60, 60, 0.5);
  font-size: 14px;
}
.vue-treeselect__input-container input {
  font-weight: bold !important;
}
.display-none {
  display: none;
}
</style>
